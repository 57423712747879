<template>
  <div class="ps-lg-7 pt-4">
    <h1 class="text-h6 fw-700 mb-3">
      {{ $t('flow_no') }}: {{ order.code }}
    </h1>
    <Summary :order-details="order" @reload="getDetails($route.params.code)" />
  </div>
</template>

<script>
import Summary from '@/components/order/Summary'

export default {
  data() {
    return {
      dialog: false,
      order: {}
    }
  },
  components: {
    Summary
  },
  methods: {
    async getDetails(orderCode) {
      const res = await this.call_api('get', `user/order/${orderCode}`)
      if (res.data.success) {
        this.order = res.data.data
      } else {
        this.snack({
          message: res.data.message,
          color: 'red'
        })
        return
      }
    }
  },
  created() {
    this.getDetails(this.$route.params.code)
  }
}
</script>
